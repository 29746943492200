@include colorBase();

$main-theme-color:  #2a5774;
$invite-others-link: #337ab7;

// Icon
$start-sprint-icon-color: #7698a4 ;

// Button 
$add-comment-btn: $cool ;


// Placeholder Color
$dynamic-theme-placeholder-color:  rgba(255, 255, 255, 0.415);
$dynamic-theme-placeholder-date: white;

// Comment
$comment-editor-background: rgba(100,100,100,1);
$system-operation-comment-background: #414040;

// Toggle
$toggle-color: #fff;
$toggle-hover: #adbec6;
$toggle-active: #b2c8d4;
$toggle-select: #adbec6;
$toggle-hover-opacity: rgba(173, 190, 198,0.5);


// Tab 
$tab-color: #337ab7;
$tab-active: #fff;
$tab-background-active: rgba(37, 87, 109, 0.4);
$tab-background-hover: #7fb0c5;


// Dynamic
$dynamic-thead-color: white;
$dynamic-thead-background: #9a9a9a;
$dynamic-thead-background-active: #9a9a9a;
$dynamic-dropdown-menu-active: #337ab7;
$dynamic-icon-btn-color: #d3d3d3;
$dynamic-icon-btn-hover: transparent;
$dynamic-dd-list-bottom-background: #2a5774;
$dynamic-link-hover-color: #fff;

// Datepicker
$theme-datepicker-color:#adbec6;
$theme-datepicker-hover: #7fb0c5;

$datepicker-active: $theme-datepicker-color;
$datepicker-hover: $theme-datepicker-hover;
$datepicker-day-active: $theme-datepicker-color;
$datepicker-day-hover: $theme-datepicker-hover;


// Modal 
$modal-information-header-bg: whitesmoke ; 
$modal-information-header-color: #333 ; 
$information-header-bg: $cool ;

// Modal Main System Operation 
$modal-header-bg: rgba(0,0,0,0);
$modal-header-color: #333;
$modal-nav-option-bg: #a0a0a0;
$modal-nav-option-hover: #676767;
$modal-nav-option-active: #737373;
$modal-nav-option-color: #fff; 


// Nav
$nav-sidebar-icon-color:  #fff ; 
$nav-sidebar-background-hover:  #5c7f8b ;
$nav-sidebar-background-seleted: #5c7f8b ;
$navBar-selected: #1c3b4f ;
$nav-sidebar-icon-hover: #fff ;

$nav-member-title-background: lightgray;
$nav-member-title-color:  black;

// Profile
$profile-nav-tabs-color: #337ab7;
$profile-nav-tabs-hover: #7fb0c5;
$profile-nav-tabs-active:rgba(37,87,109,0.4);
$profile-nav-tabs-active-color: #fff;


// SideBar
$notice-header-background:#3f94b9;
$notice-header-color: $white;

$notice-filter-background: #3f94b9;
$notice-filter-mobile-background: #fff;
$notice-filter-mobile-color: rgb(150,150,150);
$notice-filter-color: $white;

$notice-main-background:  #3f94b9;
$notice-mobile-background:  #fff; 
$notice-main-color: $white;

$notice-card-background: #386375;
$notice-card-background-hover: #274653;
$notice-card-color: $white;

$notice-tab-background: #62aac9;
$notice-tab-active: #25576d;
$notice-tab-hover: #183947;

$projectSetting-menu-header-bg:rgba(40, 63, 74, 0.315);//頭
$projectSetting-menu-header-color: #fff;
$projectSetting-content-bg: lighten(rgb(122, 142, 151), 10%) ;//$white; 
$projectSetting-content-color: #eee;
$projectSetting-input-color: gray;
$projectSetting-filter-border: #52717b; 
$projectSetting-filter-bg: #52717b; 
$projectSetting-filter-color: $white; 
$projectSetting-filter-input: #333;

$calendar-backgound: rgba(117,180,208,0.4);

$filter-block-dropdown-open: #7a8e97;//內容條
$filter-block-background: #7a8e97;


// MobileMenu Panel
$mobileMenu-panel-project-background: #cee8ef ;
$mobileMenu-panel-todo-background: #fcf1be ;
$mobileMenu-panel-backlog-background: #efd9f4 ;
$mobileMenu-panel-review-background: #f9c9c7  ;
$mobileMenu-panel-effort-background: ghostwhite ;
$mobileMenu-panel-managed-background: ghostwhite ;

$mobileMenu-main: #efefef ;
$otis-card-background: whitesmoke;
$otis-card-body-background: white;



// Todo Detail
$todo-nav-option-bg: rgba(37,87,109,0.8);
$todo-nav-option-hover: #9c9c9c;
$todo-nav-option-active: #838383;
$todo-nav-option-color: #fff; 




// Backlog 
$filter-factor-color: rgba(255,255,255,0.3);
$filter-factor-selected-background: rgba(0,0,0,0.2);
$filter-factor-selected-color: rgba(255,255,255,1);
$backlog-page-title-toggle: #b2c8d4;
$backlog-page-title-active: #adbec6;

// File View
$fileView-hover-background: lighten(#cce8ff, 3%);
$folder-row-selected: #619dce ;
$folder-row-hover: #61aaca ;
$folder-row-active: #1f485a ;
$folder-row-active-color: #fff;
$edit-icon-hover: $toggle-hover;
$edit-icon-active: $toggle-active;

$fileView-filter-hover: #75bad0;
$fileView-filter-color: #fff;


// Discussion
$discussion-panel-selected-background: #3885a6;
$discussion-panel-selected-color: #fff;
// $discussion-panel-background-hover: #61aaca ;
$discussion-panel-background-hover: #eee;
$discussion-panel-color-hover: #333;

$discussion-topic-odd-panel: #d4e8f0; 
$discussion-topic-event-panel: #e7f2f7; 

$discussion-sel-topic-title-left: #2a5774;
$discussion-sel-topic-title: #3885a6;

$discussion-topic-add-button: $btn-primary;
$discussion-topic-add-button-border: $btn-primary-border;

$discussion-comment-editor-info: #FFF;
$cmt-editor-btn-color: #fff;
$info-assignment-color: #fff;
$edit-comment-color: #fff;
$modal-editing-cmt-info-color: #333;

// List View




// ================== Old color ============== //
$mainColor: $warm;
$main-text:rgb(105,105,105);
$main-text-hover:rgb(38,170,226);
$overlay-color: rgba(20,20,20,0.9);
$dropDrown-color:$warm;
$projectCardHover-background:   #cddcee;
$projectCardWatchIcon-color: #afcde6;
$sprintTitleOpition-hoverColor: $overlay-color;


$main-background-color-new: darken(rgb(77,142,171), 0%);
$main-background-color: darken(rgb(77,142,171), 0%);
//$main-background-color: $calm;
$main-background-hover:darken($main-background-color,5%);
$main-background-focus:darken($main-background-color,5%);
$even-background-color: lighten($main-background-color, 50%);
$odd-background-color:  lighten($main-background-color, 55%);
$addSprint-text-color: #fff; 
$noticeBackground: rgb(63,148,185);
$noticeCardBackground:rgb(56,99,117);
$myTodoListHeaderColor:rgb(19,85,137);

$fileViewHover-border-color: darken(#cce8ff, 15%);
$fileView-selected-background: #cce8ff;

$navBarColor: darken(rgb(42, 87, 116),0%);
$header-text:white;
$navSidebarColor: lighten(rgb(72,99,108),20%);
$projectBodyBackground: lighten(rgb(42, 87, 116),10%);
$viewTitleBackground: lighten(rgb(42, 87, 116),10%);
$viewTitleTextColor: white;
$viewTitleTextHovered: gold;
$inverseViewTitleTextColor: $warm;

$addProjectCardColor:lighten($calm, 20%);
$recentProjectCardColor:lighten($cool, 20%);
$joinedProjectCardColor:  lighten($calm, 20%);
$archivedProjectCardColor:  lighten($main-text, 0%);

$activeSprintColor:darken(rgb(188,202,147), 0%);
$generalSprintColor: lighten($main-text, 25%);
$finishedSprintColor: rgb(172, 172, 168);
$uncalssifiedBacklogColor: $warm;
$projectTplBackground: $calm ;

$doingSprintColor: darken( rgb(255,205,106), 20%);
$sprintViewBackground: rgba(255,255,255,0.23);
$sprintDetailColor: darken(rgb(188,202,147), 0%);
$doingTodoColor : rgb(255,222,118);
$memberViewColor: lighten($warm, 15%);
$memberViewText-hover:#fff;


$memberViewBacklogColor: lighten($memberViewColor, 15%);

$unStartSprintLight: 5%;
$unStartSprintDark: 0%;
$phaseViewBottomPercent : 10%;
$memberViewBottomPercent: 5%;
$memberViewTodoCardTextColor: $viewTitleTextColor;

$todoListBorderStyle: 1px solid transparent;
$waitTodoListBkColor: lighten($activeSprintColor,10%);
$waitTodoListBkColorHeader: lighten($activeSprintColor,10%);
$waitTodoListBkColorBottom: lighten($activeSprintColor,0%);
$waitTodoListBkColorContent: lighten($waitTodoListBkColor,100%);
$waitTodoListTextColor: $warm;

$doingTodoListBkColor:lighten($doingSprintColor,15%);
$doingTodoListBkColorHeader:  lighten($doingTodoListBkColor,15%);
$doingTodoListBkColorBottom: lighten($doingTodoListBkColor,5%);
$doingTodoListBkColorContent: lighten($doingTodoListBkColor, 100%);
$doingTodoListTextColor: $warm;

$haveDoneListBkColor: gray;
$haveDoneListBkColorHeader: darken($haveDoneListBkColor, 10%);
$haveDoneListBkColorBottom: lighten($haveDoneListBkColor,15%);
$haveDoneListBkColorContent: lighten($haveDoneListBkColor,10%);
$haveDoneListTextColor:  lighten($main-text, 60%);

$titleBarColor: darken(rgba(230,230,230,1), 5%);
$listHeaderGeneral: $titleBarColor;
$otisLeadColor: #5180B7;
$myselfIconBkColor: #8EB2D6;
$memberIconBkColor: #306A99;
$clientIconBkColor: lightskyblue;
$memberColor: lighten($myselfIconBkColor,20%);
$pmColor: gold ;
$plColor: orange;

$todoCommentEditorBackground: rgba(100,100,100,1);

$body-background: $projectBodyBackground;
$dynamicBackground: rgba($mainColor,0.4);
$dynamicBackgroundTransparent: rgba($mainColor,0.9);
$dynamicBackgroundDarker: rgba($mainColor,0.8);
$dynamicBackgroundSolid: rgba($mainColor,1);
$dynamicLinkColor: steelblue;
$dynamicOpacityFactor: 0.96;
$dynamicReportBackground: rgba(0,0,0,0.8);
$dynamicPlaceHolderColor: whitesmoke;
$dynamicTextColor-dark: lightgray;
$dynamicToggledColor: navajowhite;
$dynamicColorGray: lightgray;

//$mainBackgroundColor: rgb(97,99,99);


$backlogOpacityFactor: 40%;

$backgroundImg: "../../assets/img/otis-defined/otisBk2.jpg";
$backgroundImgSprint: "../../assets/img/otis-defined/otisBk1.jpg";
$selectArrow: "../../assets/img/otis-defined/arrow-B.png";
$sprintBackgroundColor: #e1dad0;

$projectInfoActive-background:rgba(65,64,64,0.96);
$projectInfoActive-color:#fff;
$projectInfoActive-background-hover:lightblue;
$projectInfoTab-color:#fff;
$discussionColor: $warm;
$discussionChatColor: lighten(aliceblue, 0%);
$discussionBtnColor: $cool;
$discussionTimeColor:rgba(37,37,37,0.6);
$commentsHyperlinkColor: $warm;

$calendarBorderColor: $main-background-color;
$reportSubTitle: navajowhite;
$reportEffort: lightblue;

$listViewFilterPanelBackground: rgba(0,0,0,0.1);
$backlogViewFilterPanelBackground: rgba( darken( $navSidebarColor, 5%),1 );
